import Swiper from 'swiper/bundle'

const bannerSlider = new Swiper('.js-banner-slider .swiper-container', {
  loop: true,
  grabCursor: true,

  pagination: {
    el: '.js-banner-pagination'
  }
})
