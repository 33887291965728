import Swiper from 'swiper/bundle'

if($('.js-main-slider .swiper-slide').length > 1) {
  const mainSlider = new Swiper('.js-main-slider .swiper-container', {
    loop: true,
    speed: 700,
    grabCursor: true,

    autoplay: {
      delay: 5000,
    },

    pagination: {
      el: '.js-main-pagination'
    },

    on: {
      slideChange() {
        const { activeIndex } = this;
        const currentSlide = $('.js-main-slider .swiper-slide').eq(activeIndex);
        console.log(('.js-main-slider .swiper-slide').length)

        if(currentSlide.hasClass('js-main-video')) {
          currentSlide.find('video')[0].load()
        }
      }
    }
  })
}


